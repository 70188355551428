import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import { Helmet } from "react-helmet"
import cx from "classnames"

import Layout from "../../modules/layout"
import Breadcrumbs from "../../modules/breadcrumbs"
import BlockContent from "../../components/page-builder/richText"
import Form from "../../modules/form"

import styles from "./event.module.scss"

export const query = graphql`
  query($slug: String) {
    sanityEvent(slug: { current: { eq: $slug } }) {
      title
      dateStart(formatString: "MM-DD-YYYY")
      dateEnd(formatString: "MM-DD-YYYY")
      _rawBody
      excerpt
      location
      formTitle
      _rawFormSubtitle
      formLink
      image {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`

const EventTemplate = props => {
  const { data, location } = props
  const post = data && data.sanityEvent
  const isImage = post.image && post.image.asset !== null
  const isSidebar = isImage || post.dateStart || post.location
  const isBody = post._rawBody || post.excerpt
  const isOneColumn = !isBody || !isImage

  const eventContentClass = cx(styles.eventContent, {
    [styles.eventContent__oneColumn]: isOneColumn,
  })

  return (
    <>
      <Layout>
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
        <Breadcrumbs location={location}>
          <Link to="/">Home</Link>
          <Link to="/why-peoplefluent/events/">Events</Link>
          <p>{post.title}</p>
        </Breadcrumbs>
        <div className={styles.eventPage}>
          <h1 className={styles.eventHeader}>{post.title}</h1>
          <section className={eventContentClass}>
            {isSidebar && (
              <div className={styles.eventSidebar}>
                {post.dateStart ? (
                  <div>
                    {post.dateStart} {post.dateEnd && `- ${post.dateEnd}`}
                  </div>
                ) : null}
                {post.location && <div>{post.location}</div>}
                {isImage && (
                  <Img
                    className={styles.eventImage}
                    fluid={post.image.asset.fluid}
                  />
                )}
              </div>
            )}
            {isBody && (
              <div>
                {post._rawBody ? (
                  <BlockContent blocks={post._rawBody} />
                ) : (
                  <div className={styles.eventExcerpt}>{post.excerpt}</div>
                )}
              </div>
            )}
          </section>
          {post.formLink && (
            <section className={styles.eventForm}>
              <Form
                title={post.formTitle}
                link={post.formLink}
                subtitle={post._rawFormSubtitle}
              />
            </section>
          )}
        </div>
      </Layout>
    </>
  )
}

export default EventTemplate
